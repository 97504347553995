.c-alert {
	padding: 1rem;
	box-shadow: inset 0.7px 0.8px 2px 0px rgba(58, 58, 58, 0.25);
	border: 1px solid $color_gray_2;
	color: $color_gray_2;
	background: $color_gray_3;
	font-weight: bold;
	border-radius: 1.4rem;
	text-align: left;

	@include media(">m") {
		padding: 1.5rem;
	}

	&--error {
		border-color: $color_red;
		color: $color_red;
		background: $color_red_2;
	}
	
	&--success {
		border-color: $color_green;
		color: $color_green;
		background: $color_green_2;
	}
}