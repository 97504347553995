.c-menu {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	padding-top: 1.5rem;
	background-color: $color_blue_2;
	z-index: 1100;
	color: $color_white;
	overflow-y: auto;
	width: 33rem;

	&:not(.is-opened) {
		display: none;
	}

	&__header {
		display: grid;
		grid-template-columns: auto auto;
		grid-gap: 1.5rem;
		margin-bottom: 1.5rem;
	}

	&__close {
		display: inline-block;
		padding: 0 1.5rem;

		img {
			margin: 0;
		}

		@include media(">2030px") {
			display: none;
		}
	}

	&__content {
		@include media("<=m") {
			margin-bottom: 7rem;
		}
	}

	&__footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 26rem;
		padding: 1.5rem;
		background-color: $color_yellow;
		border-top: 1px solid $color_blue;

		.button,
		button {
			box-shadow: none;
		}

		@include media(">m") {
			display: none;
		}
	}
}