/* Include media
   ========================================================================== */
/* Base
   ========================================================================== */
/* Colors
========================================================================== */
/* Fonts
========================================================================== */
/* Breakpoints
========================================================================== */
/* ==========================================================================
   Mixins
   ========================================================================== */
/* 1. Animation
   ========================================================================== */
/* Contents
   ========================================================================== */
hr {
  border-top: 1px solid #dbdbdb;
  margin: 0; }

table {
  display: block; }
  table tbody {
    display: table; }
    @media (max-width: 670px) {
      table tbody {
        width: 100%; } }
  table th, table td {
    padding: 1.5rem; }
  table th {
    text-align: left; }
  table tr:nth-child(odd) {
    background-color: #f4f4f4; }
  table tbody, table th, table td {
    border: 1px solid #dbdbdb;
    border-collapse: collapse; }

/* Components
   ========================================================================== */
.c-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 1.5rem;
  background-color: #223881;
  z-index: 1100;
  color: #fff;
  overflow-y: auto;
  width: 33rem; }
  .c-menu:not(.is-opened) {
    display: none; }
  .c-menu__header {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1.5rem;
    margin-bottom: 1.5rem; }
  .c-menu__close {
    display: inline-block;
    padding: 0 1.5rem; }
    .c-menu__close img {
      margin: 0; }
    @media (min-width: 2031px) {
      .c-menu__close {
        display: none; } }
  @media (max-width: 670px) {
    .c-menu__content {
      margin-bottom: 7rem; } }
  .c-menu__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 26rem;
    padding: 1.5rem;
    background-color: #dff04c;
    border-top: 1px solid #3f3fbb; }
    .c-menu__footer .button,
    .c-menu__footer button {
      box-shadow: none; }
    @media (min-width: 671px) {
      .c-menu__footer {
        display: none; } }

.c-nav li {
  position: relative;
  white-space: nowrap;
  margin: 2.5rem 0 0; }
  .c-nav li:first-child {
    margin-top: 0; }
  .c-nav li a,
  .c-nav li span {
    display: inline-block; }
  .c-nav li > a:first-child:nth-last-child(2) {
    background-position: 100% 2.3rem;
    background-repeat: no-repeat; }
  .c-nav li > a,
  .c-nav li > span {
    padding: 1.5rem 0;
    width: 100%;
    text-decoration: none;
    font-size: 2.3rem;
    font-weight: bold; }
  .c-nav li ul {
    margin: 0;
    grid-column: 1 / 3;
    grid-row: 2 / 3; }
    .c-nav li ul li {
      background: none;
      float: none;
      width: 100%;
      margin-top: 0; }
      .c-nav li ul li a {
        font-size: inherit;
        font-weight: initial; }

.c-nav > li > a,
.c-nav > li > span {
  color: #3a3a3a; }

.c-nav > li a:first-child:nth-last-child(2),
.c-nav > li div:before {
  background-image: url("../img/svg/nav_arrow_blue.svg"); }

.c-nav--expand li {
  display: grid;
  grid-template-columns: auto 5rem;
  grid-template-rows: auto auto; }
  .c-nav--expand li > a,
  .c-nav--expand li > span {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    cursor: pointer; }
  .c-nav--expand li div {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    cursor: pointer;
    width: 100%;
    line-height: normal;
    position: relative; }
    .c-nav--expand li div:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: 50% 50%;
      background-repeat: no-repeat; }
    .c-nav--expand li div:after {
      padding-bottom: 100%;
      content: '';
      display: inline-block;
      vertical-align: middle; }
  .c-nav--expand li:not(.is-opened) ul {
    display: none; }
  .c-nav--expand li:not(.is-opened) div:before {
    transform: rotate(180deg); }

.c-nav--dark li > a,
.c-nav--dark li > span {
  padding: 1.5rem; }

.c-nav--dark > li > a,
.c-nav--dark > li > span {
  color: #fff; }

.c-nav--dark > li a:hover {
  background-color: #3f3fbb; }

.c-nav--dark > li ul a {
  color: #dff04c; }

.c-nav--dark > li a:first-child:nth-last-child(2),
.c-nav--dark > li div:before {
  background-image: url("../img/svg/nav_arrow_yellow.svg"); }

.c-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ededed;
  z-index: 2000;
  overflow-y: auto;
  padding: 0 2rem; }
  @media (min-width: 980px) {
    .c-popup {
      padding-bottom: 1.5rem; } }
  .c-popup:not(.is-opened) {
    display: none; }
  .c-popup__header, .c-popup__wrapper {
    position: relative; }
  .c-popup__header {
    padding-top: 1.5rem; }
    @media (min-width: 980px) {
      .c-popup__header {
        display: none; } }
  .c-popup__wrapper {
    margin: 4rem auto 0; }
    @media (min-width: 980px) {
      .c-popup__wrapper {
        margin-top: 11rem; } }
    .c-popup__wrapper > * {
      padding-bottom: 4rem; }
  @media (max-width: 979px) {
    .c-popup__wrapper .c-popup__close {
      display: none; } }
  .c-popup--welcome .c-popup__wrapper {
    max-width: 69rem; }
  @media (min-width: 980px) {
    .c-popup--welcome .c-popup__wrapper .c-popup__close {
      right: -3rem; } }
  .c-popup--upsell .c-popup__wrapper {
    text-align: center; }
  .c-popup__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem; }

.c-tab > *:not(.is-opened):not(.c-tab__items) {
  display: none; }

.c-tab__items {
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: -2.7px -3px 7px 0 #fff, 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
  background-color: #f4f4f4;
  z-index: 1; }
  .c-tab__items::after {
    content: "";
    clear: both;
    display: table; }

.c-tab__item {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1.5rem 4rem;
  color: #3f3fbb;
  text-shadow: 0.5px 0.9px 0 #fff;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center; }
  .c-tab__item.is-opened {
    box-shadow: 0.8px 1.8px 7px 0 #fff, inset 2.6px 3px 7px 0 rgba(58, 58, 58, 0.11);
    background-image: linear-gradient(to top, #e5e5e5, #ededed);
    color: #3a3a3a; }
  @media (max-width: 670px) {
    .c-tab__item {
      padding: 1.5rem; } }

.c-tab__content {
  padding: 2rem; }
  @media (min-width: 1411px) {
    .c-tab__content {
      padding: 3rem 0; } }

.c-review {
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 1.5rem; }
  .c-review__value {
    font-size: 2.3rem;
    font-weight: bold; }
  .c-review__rating {
    align-items: center; }

.c-captcha {
  outline: 0;
  border: 1px solid #dbdbdb;
  border-radius: 0.3rem;
  font-weight: bold;
  font-size: 3rem;
  letter-spacing: 0.06rem;
  text-align: center;
  min-height: 10rem;
  position: relative; }
  .c-captcha > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: pre; }

.c-alert {
  padding: 1rem;
  box-shadow: inset 0.7px 0.8px 2px 0px rgba(58, 58, 58, 0.25);
  border: 1px solid #dbdbdb;
  color: #dbdbdb;
  background: #f4f4f4;
  font-weight: bold;
  border-radius: 1.4rem;
  text-align: left; }
  @media (min-width: 671px) {
    .c-alert {
      padding: 1.5rem; } }
  .c-alert--error {
    border-color: #df3d58;
    color: #df3d58;
    background: rgba(223, 61, 88, 0.1); }
  .c-alert--success {
    border-color: #62ca54;
    color: #62ca54;
    background: rgba(98, 202, 84, 0.1); }

.c-accordion {
  border-bottom: 1px solid #ededed; }
  .c-accordion__header {
    position: relative;
    cursor: pointer;
    font-weight: 700;
    margin: 0 0 1.5rem;
    padding: 0.5rem 0 0.5rem 4.4rem; }
    @media (min-width: 1201px) {
      .c-accordion__header {
        padding: 0.5rem 0 0.5rem 4.4rem; } }
    .c-accordion__header:before, .c-accordion__header:after {
      position: absolute;
      content: '';
      width: 2.8rem;
      height: 2.8rem;
      left: 0;
      top: 0;
      border-radius: 1.4rem;
      background: 0.8rem 0.8rem url("../img/svg/faq_plus.svg") no-repeat; }
    .c-accordion__header::before {
      box-shadow: -2.7px -3px 7px 0 #fff;
      background-color: #f4f4f4; }
    .c-accordion__header::after {
      box-shadow: 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
      background-color: #f4f4f4; }
  .c-accordion__text {
    visibility: hidden;
    height: 0; }
  .c-accordion.is-opened .c-accordion__text {
    visibility: visible;
    padding: 1.5rem 0;
    height: auto;
    margin-bottom: 4rem; }
  .c-accordion.is-opened .c-accordion__header::before, .c-accordion.is-opened .c-accordion__header::after {
    background: 0.8rem 0.8rem url("../img/svg/faq_minus.svg") no-repeat; }
  .c-accordion.is-opened .c-accordion__header::before {
    box-shadow: inset 2.6px 3px 6.9px 0.1px rgba(58, 58, 58, 0.11);
    background-image: linear-gradient(to top, #e5e5e5, #ededed); }
  .c-accordion.is-opened .c-accordion__header::after {
    box-shadow: 0.8px 1.8px 7px 0 #fff, inset 2.6px 3px 6.9px 0.1px rgba(58, 58, 58, 0.11); }

.c-display-header {
  display: grid;
  grid-gap: 3rem; }
  @media (min-width: 980px) {
    .c-display-header {
      grid-template-columns: repeat(auto-fit, minmax(0, auto));
      align-items: center; }
      .c-display-header h2, .c-display-header h3, .c-display-header h4, .c-display-header h5 {
        text-align: left; } }
  .c-display-header__select {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: end;
    align-items: center; }
    .c-display-header__select label {
      text-transform: uppercase;
      margin-right: 1.5rem;
      padding-top: 0; }

.c-display {
  padding: 1.5rem;
  box-shadow: 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
  background-color: #fff;
  border-radius: 1.4rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: auto 1fr; }
  .c-display__image {
    position: relative; }
  .c-display__badge {
    position: absolute;
    left: -2rem;
    top: -0.9rem;
    height: 2.2rem;
    color: #fff;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.66px;
    text-transform: uppercase;
    text-shadow: 0px -1px 0 rgba(58, 58, 58, 0.83); }
  .c-display--top .c-display__badge {
    background: 0 0 url("../img/svg/badge_top.svg") no-repeat;
    width: 4.4rem; }
  .c-display--vip .c-display__badge {
    background: 0 0 url("../img/svg/badge_vip.svg") no-repeat;
    width: 4.1rem; }
  .c-display--exclusive .c-display__badge {
    background: 0 0 url("../img/svg/badge_exc.svg") no-repeat;
    width: 8.7rem; }
  @media (max-width: 669px) {
    .c-display h3 {
      font-size: 1.9rem;
      line-height: 2.6rem; }
    .c-display h4 {
      font-size: 1.6rem;
      line-height: 2.3rem; } }
  .c-display--casino {
    grid-template-columns: 8.5rem auto;
    grid-template-rows: auto auto auto auto auto auto; }
    @media (min-width: 670px) {
      .c-display--casino {
        grid-template-columns: 15rem  auto auto;
        grid-template-rows: auto auto auto auto; } }
    @media (min-width: 980px) {
      .c-display--casino {
        grid-template-columns: 15rem  auto auto auto;
        grid-template-rows: initial; } }
  .c-display--casino .c-display__image {
    grid-column: 1 / 2;
    grid-row: 3 / 4; }
    .c-display--casino .c-display__image img {
      border-radius: 1rem;
      display: block; }
    @media (min-width: 670px) {
      .c-display--casino .c-display__image {
        grid-row: 1 / 3; }
        .c-display--casino .c-display__image img {
          border-radius: 1.4rem; } }
  .c-display--casino .c-display__name {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    font-weight: bold;
    align-self: center; }
    @media (min-width: 670px) {
      .c-display--casino .c-display__name {
        grid-column: 2 / 3; }
        .c-display--casino .c-display__name * {
          text-align: left; } }
  .c-display--casino .c-display__rating {
    grid-column: 1 / 3;
    grid-row: 2 / 3; }
    @media (max-width: 669px) {
      .c-display--casino .c-display__rating {
        display: grid;
        justify-content: center; } }
    @media (min-width: 670px) {
      .c-display--casino .c-display__rating {
        grid-column: 2 / 3; } }
  .c-display--casino .c-display__info {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    font-weight: bold; }
    @media (min-width: 670px) {
      .c-display--casino .c-display__info {
        grid-column: 3 / 4;
        grid-row: 1 / 3; } }
  .c-display--casino .c-display__promo {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    align-self: center;
    justify-items: center; }
    .c-display--casino .c-display__promo img {
      margin-right: 0; }
    @media (min-width: 670px) {
      .c-display--casino .c-display__promo {
        grid-column: 1 / 4;
        grid-row: 3 / 4; } }
    @media (min-width: 980px) {
      .c-display--casino .c-display__promo {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
        grid-template-columns: 1fr auto;
        text-align: right;
        justify-items: initial; } }
  .c-display--casino .c-display__actions {
    grid-column: 1 / 3;
    grid-row: 5 / 6; }
    @media (min-width: 670px) {
      .c-display--casino .c-display__actions {
        grid-column: 1 / 4;
        grid-row: 4 / 5; } }
    @media (max-width: 979px) {
      .c-display--casino .c-display__actions {
        display: grid;
        justify-content: center;
        grid-gap: 2rem; } }
    @media (min-width: 980px) {
      .c-display--casino .c-display__actions {
        grid-column: 4 / 5;
        grid-row: 2 / 3;
        text-align: right; } }
  .c-display--bonus {
    grid-template-columns: 8.5rem auto;
    grid-template-rows: auto auto auto auto auto; }
    @media (min-width: 670px) {
      .c-display--bonus {
        grid-template-columns: 15rem 10rem auto;
        grid-template-rows: auto auto auto auto; } }
    @media (min-width: 980px) {
      .c-display--bonus {
        grid-template-columns: 15rem 10rem auto auto;
        grid-template-rows: initial; } }
  .c-display--bonus .c-display__image {
    grid-column: 1 / 2;
    grid-row: 1 / 3; }
    .c-display--bonus .c-display__image img {
      border-radius: 1.4rem;
      display: block; }
  .c-display--bonus .c-display__name {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    font-weight: bold;
    align-self: center; }
    .c-display--bonus .c-display__name * {
      text-align: left; }
    @media (min-width: 670px) {
      .c-display--bonus .c-display__name {
        grid-column: 3 / 4; } }
  .c-display--bonus .c-display__rating {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: center; }
    @media (min-width: 670px) {
      .c-display--bonus .c-display__rating {
        grid-row: 1 / 2; } }
  .c-display--bonus .c-display__info {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    font-weight: bold;
    align-self: center; }
    @media (min-width: 670px) {
      .c-display--bonus .c-display__info {
        grid-column: 2 / 4;
        grid-row: 2 / 3; } }
  .c-display--bonus .c-display__promo {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    align-self: center; }
    .c-display--bonus .c-display__promo img {
      margin-right: 0; }
    @media (min-width: 670px) {
      .c-display--bonus .c-display__promo {
        grid-column: 1 / 4;
        grid-row: 3 / 4; } }
    @media (min-width: 980px) {
      .c-display--bonus .c-display__promo {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
        grid-template-columns: 1fr auto;
        text-align: right; } }
  .c-display--bonus .c-display__actions {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
    align-items: center;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr auto; }
    @media (min-width: 670px) {
      .c-display--bonus .c-display__actions {
        grid-column: 1 / 4;
        grid-row: 4 / 5; } }
    @media (min-width: 980px) {
      .c-display--bonus .c-display__actions {
        grid-column: 4 / 5;
        grid-row: 2 / 3;
        text-align: right; } }

.c-newsletter {
  display: grid;
  grid-gap: 2rem;
  margin: 3rem 0; }
  .c-newsletter__consent input {
    float: left; }
  .c-newsletter__consent p {
    padding-left: 2.7rem;
    margin-top: -0.5rem; }
  .c-newsletter__actions {
    display: grid;
    justify-items: center; }
  @media (min-width: 980px) {
    .c-newsletter {
      grid-gap: 3rem;
      grid-template-columns: 16.5rem auto 34rem;
      margin-bottom: 4rem; }
      .c-newsletter__header, .c-newsletter__info {
        grid-column: 1 / 4; }
      .c-newsletter__items {
        grid-column: 1 / 3; }
      .c-newsletter__actions {
        grid-column: 2 / 3;
        justify-items: end; }
      .c-newsletter__consent {
        grid-column: 3 / 4;
        grid-row: span 2; } }
  @media (max-width: 979px) {
    .c-newsletter {
      padding: 0 2rem; } }

.c-contact {
  display: grid;
  grid-gap: 1.5rem;
  margin: 3rem 0;
  text-align: center; }
  @media (min-width: 980px) {
    .c-contact {
      grid-template-columns: 16.5rem auto;
      margin-bottom: 4rem;
      margin-right: 3rem; }
      .c-contact__header, .c-contact__info {
        grid-column: 1 / 3; }
      .c-contact__items {
        grid-column: 1 / 3; }
      .c-contact__actions {
        grid-column: 2 / 3;
        text-align: right;
        display: grid;
        grid-gap: 1.5rem; } }
  @media (max-width: 979px) {
    .c-contact {
      padding: 0 2rem; } }

.c-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  margin: 0 -2rem; }
  @media (min-width: 980px) {
    .c-footer {
      margin: 0;
      padding: 4rem 0;
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: auto; } }
  @media (max-width: 979px) {
    .c-footer__left, .c-footer__center, .c-footer__right {
      padding: 2rem; } }
  .c-footer__left {
    grid-column: 1 / 2; }
  .c-footer__center {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    text-align: center;
    border-top: 1px solid #dbdbdb; }
    @media (min-width: 980px) {
      .c-footer__center {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        border-top: none; } }
  .c-footer__right {
    grid-column: 2 / 3;
    text-align: right; }
    @media (min-width: 980px) {
      .c-footer__right {
        grid-column: 3 / 4; } }

/* Layouts
   ========================================================================== */
.s-advice {
  display: grid;
  grid-gap: 2.5rem;
  padding: 4rem 0; }
  @media (max-width: 1199px) {
    .s-advice img {
      display: block;
      margin: 0 auto; } }
  @media (min-width: 1200px) {
    .s-advice {
      align-items: center;
      grid-template-columns: 19.8rem auto; } }

.s-faq {
  margin-bottom: 2.5rem; }
  @media (max-width: 1199px) {
    .s-faq {
      padding: 0 2rem; } }

.s-footer-menu {
  padding: 4rem 0; }
  .s-footer-menu h2, .s-footer-menu h3, .s-footer-menu h4, .s-footer-menu h5 {
    text-align: left; }
  .s-footer-menu ol,
  .s-footer-menu ul {
    margin-top: 3rem; }

.s-item-with-pic {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: auto 1fr; }
  @media (min-width: 481px) {
    .s-item-with-pic {
      align-items: center; } }
  .s-item-with-pic:hover {
    text-decoration: none; }
    .s-item-with-pic:hover span {
      text-decoration: underline; }

.s-display {
  display: grid;
  grid-gap: 2rem; }
  @media (min-width: 980px) {
    .s-display {
      grid-gap: 1.5rem; } }
