.c-accordion {
	border-bottom: 1px solid $color_gray;
	
	&__header {
		position: relative;
		cursor: pointer;
		font-weight: 700;
		margin: 0 0 1.5rem;
		padding: 0.5rem 0 0.5rem 4.4rem;
		
		@include media(">t") {
			padding: 0.5rem 0 0.5rem 4.4rem;
		}

		&:before,
		&:after {
			position: absolute;
			content: '';
			width: 2.8rem;
			height: 2.8rem;
			left: 0;
			top: 0;
			border-radius: 1.4rem;
			background: 0.8rem 0.8rem url('../img/svg/faq_plus.svg') no-repeat;
		}

		&::before {
			box-shadow: -2.7px -3px 7px 0 $color_white;
			background-color: $color_gray_3;
		}

		&::after {
			box-shadow: 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
			background-color: $color_gray_3;
		}
	}

    &__text {
    	visibility: hidden;
    	height: 0;
    }

    &.is-opened &__text {
    	visibility: visible;
    	padding: 1.5rem 0;
    	height: auto;
    	margin-bottom: 4rem;
    }

    &.is-opened &__header {
    	&::before,
    	&::after {
    		background: 0.8rem 0.8rem url('../img/svg/faq_minus.svg') no-repeat;
    	}

    	&::before {
			box-shadow: inset 2.6px 3px 6.9px 0.1px rgba(58, 58, 58, 0.11);
  			background-image: linear-gradient(to top, $color_gray_4, $color_gray_5);
		}

		&::after {
			box-shadow: 0.8px 1.8px 7px 0 $color_white, inset 2.6px 3px 6.9px 0.1px rgba(58, 58, 58, 0.11);
    	}
    }
}