.c-contact {
    display: grid;
    grid-gap: 1.5rem;
    margin: 3rem 0;
    text-align: center;

    @include media(">=t-m") {
        grid-template-columns: 16.5rem auto;
        margin-bottom: 4rem;
        margin-right: 3rem;

        &__header,
        &__info {
            grid-column: 1 / 3;
        }

        &__items {
            grid-column: 1 / 3;
        }

        &__actions {
            grid-column: 2 / 3;
            text-align: right;
            display: grid;
            grid-gap: 1.5rem;
        }
    }

    @include media("<t-m") {
        padding: 0 2rem;
    }
}
