/* Include media
   ========================================================================== */

@import "node_modules/include-media/dist/_include-media.scss";

/* Base
   ========================================================================== */

@import "partials/base/variables";
@import "partials/base/mixins";

/* Contents
   ========================================================================== */

@import "partials/contents/init_main";

/* Components
   ========================================================================== */

@import "partials/components/init_main";

/* Layouts
   ========================================================================== */

@import "partials/layouts/init_main";

