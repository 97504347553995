.s-item-with-pic {
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: auto 1fr;

	@include media(">m-s") {
		align-items: center;
	}

	&:hover {
		text-decoration: none;

		span {
			text-decoration: underline;
		}
	}
}