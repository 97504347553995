.c-tab {
	& > *:not(.is-opened):not(&__items) {
		display: none;
	}

	&__items {
		margin: 0;
		padding: 0;
		list-style: none;
  		box-shadow: -2.7px -3px 7px 0 $color_white, 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
  		background-color: $color_gray_3;
		z-index: 1;

		&::after {
		  content: "";
		  clear: both;
		  display: table;
		}
	}

	&__item {
		font-size: 1.5rem;
		font-weight: bold;
		padding: 1.5rem 4rem;
		color: $color_blue;
		text-shadow: 0.5px 0.9px 0 $color_white;
		text-transform: uppercase;
		cursor: pointer;
		text-align: center;

		&.is-opened {
			box-shadow: 0.8px 1.8px 7px 0 $color_white, inset 2.6px 3px 7px 0 rgba(58, 58, 58, 0.11);
  			background-image: linear-gradient(to top, $color_gray_4, $color_gray);
  			color: $color_black_2;
		}

		@include media("<=m") {
			padding: 1.5rem;
		}
	}

	&__content {
		padding: 2rem;

		@include media(">l") {
			padding: 3rem 0;
		}
	}
}