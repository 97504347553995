.c-review {
	display: inline-grid;
	grid-template-columns: auto auto;
	align-items: center;
	justify-content: center;
	grid-gap: 1.5rem;

	&__value {
		font-size: 2.3rem;
		font-weight: bold;
	}

	&__rating {
		align-items: center;
	}
}