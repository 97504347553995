.s-advice {
	display: grid;
	grid-gap: 2.5rem;
	padding: 4rem 0;

	@include media("<t") {
		img {
			display: block;
			margin: 0 auto;
		}
	}

	@include media(">=t") {
		align-items: center;
		grid-template-columns: 19.8rem auto;
	}
}