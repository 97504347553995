.c-footer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	margin: 0 -2rem;

	@include media(">=t-m") {
		margin: 0;
		padding: 4rem 0;
		grid-template-columns: 1fr auto 1fr;
		grid-template-rows: auto;
	}

	@include media("<t-m") {
		&__left,
		&__center,
		&__right {
			padding: 2rem;
		}
	}

	&__left {
		grid-column: 1 / 2;
	}

	&__center {
		grid-column: 1 / 3;
		grid-row: 2 / 3;
		text-align: center;
		border-top: 1px solid $color_gray_2;

		@include media(">=t-m") {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
			border-top: none;
		}
	}

	&__right {
		grid-column: 2 / 3;
		text-align: right;

		@include media(">=t-m") {
			grid-column: 3 / 4;
		}
	}
}