table {
    display: block;

    tbody {
        display: table;

        @include media("<=m") {
            width: 100%;
        }
    }

    th, td {
        padding: 1.5rem;
    }

    th {
        text-align: left;
    }

    tr:nth-child(odd) {
        background-color: $color_gray_3;
    }

    tbody, th, td {
        border: 1px solid $color_gray_2;
        border-collapse: collapse;
    }
}
