.c-display {
    padding: 1.5rem;
    box-shadow: 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
    background-color: $color_white;
    border-radius: 1.4rem;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-rows: auto 1fr;

    &__image {
        position: relative;
    }

    &__badge {
        position: absolute;
        left: -2rem;
        top: -0.9rem;
        height: 2.2rem;
        color: $color_white;
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.66px;
        text-transform: uppercase;
        text-shadow: 0px -1px 0 rgba(58, 58, 58, 0.83);
    }

    &--top &__badge {
        background: 0 0 url('../img/svg/badge_top.svg') no-repeat;
        width: 4.4rem;
    }

    &--vip &__badge {
        background: 0 0 url('../img/svg/badge_vip.svg') no-repeat;
        width: 4.1rem;
    }

    &--exclusive &__badge {
        background: 0 0 url('../img/svg/badge_exc.svg') no-repeat;
        width: 8.7rem;
    }

    @include media("<m") {
        h3 {
            font-size: 1.9rem;
            line-height: 2.6rem;
        }

        h4 {
            font-size: 1.6rem;
            line-height: 2.3rem;
        }
    }

    &--casino {
        grid-template-columns: 8.5rem auto;
        grid-template-rows: auto auto auto auto auto auto;

        @include media(">=m") {
            grid-template-columns: 15rem  auto auto;
            grid-template-rows: auto auto auto auto;
        }

        @include media(">=t-m") {
            grid-template-columns: 15rem  auto auto auto;
            grid-template-rows: initial;
        }
    }

    &--casino &__image {
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        img {
            border-radius: 1rem;
            display: block;
        }

        @include media(">=m") {
            grid-row: 1 / 3;

            img {
                border-radius: 1.4rem;
            }
        }
    }

    &--casino &__name {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        font-weight: bold;
        align-self: center;

        @include media(">=m") {
            grid-column: 2 / 3;

            * {
                text-align: left;
            }
        }
    }

    &--casino &__rating {
        grid-column: 1 / 3;
        grid-row: 2 / 3;

        @include media("<m") {
            display: grid;
            justify-content: center;
        }

        @include media(">=m") {
            grid-column: 2 / 3;
        }
    }

    &--casino &__info {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        font-weight: bold;

        @include media(">=m") {
            grid-column: 3 / 4;
            grid-row: 1 / 3;
        }
    }

    &--casino &__promo {
        grid-column: 1 / 3;
        grid-row: 4 / 5;
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: 1fr 1fr;
        align-self: center;
        justify-items: center;

        img {
            margin-right: 0;
        }

        @include media(">=m") {
            grid-column: 1 / 4;
            grid-row: 3 / 4;
        }

        @include media(">=t-m") {
            grid-column: 4 / 5;
            grid-row: 1 / 2;
            grid-template-columns: 1fr auto;
            text-align: right;
            justify-items: initial;
        }
    }

    &--casino &__actions {
        grid-column: 1 / 3;
        grid-row: 5 / 6;

        @include media(">=m") {
            grid-column: 1 / 4;
            grid-row: 4 / 5;
        }

        @include media("<t-m") {
            display: grid;
            justify-content: center;
            grid-gap: 2rem;
        }

        @include media(">=t-m") {
            grid-column: 4 / 5;
            grid-row: 2 / 3;
            text-align: right;
        }
    }

    &--bonus {
        grid-template-columns: 8.5rem auto;
        grid-template-rows: auto auto auto auto auto;
        
        @include media(">=m") {
            grid-template-columns: 15rem 10rem auto;
            grid-template-rows: auto auto auto auto;
        }
        
        @include media(">=t-m") {
            grid-template-columns: 15rem 10rem auto auto;
            grid-template-rows: initial;
        }
    }
        
    &--bonus &__image {
        grid-column: 1 / 2;
        grid-row: 1 / 3;

        img {
            border-radius: 1.4rem;
            display: block;
        }
    }

    &--bonus &__name {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        font-weight: bold;
        align-self: center;

        * {
            text-align: left;
        }

        @include media(">=m") {
            grid-column: 3 / 4;
        }
    }

    &--bonus &__rating {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        align-self: center;

        @include media(">=m") {
            grid-row: 1 / 2;
        }
    }

    &--bonus &__info {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        font-weight: bold;
        align-self: center;

        @include media(">=m") {
            grid-column: 2 / 4;
            grid-row: 2 / 3;
        }
    }

    &--bonus &__promo {
        grid-column: 1 / 3;
        grid-row: 4 / 5;
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: 1fr 1fr;
        text-align: center;
        align-self: center;

        img {
            margin-right: 0;
        }

        @include media(">=m") {
            grid-column: 1 / 4;
            grid-row: 3 / 4;
        }

        @include media(">=t-m") {
            grid-column: 4 / 5;
            grid-row: 1 / 2;
            grid-template-columns: 1fr auto;
            text-align: right;
        }
    }

    &--bonus &__actions {
        grid-column: 1 / 3;
        grid-row: 5 / 6;
        align-items: center;
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: 1fr auto;
        
        @include media(">=m") {
            grid-column: 1 / 4;
            grid-row: 4 / 5;
        }
        
        @include media(">=t-m") {
            grid-column: 4 / 5;
            grid-row: 2 / 3;
            text-align: right;
        }
    }
}