.c-newsletter {
    display: grid;
    grid-gap: 2rem;
    margin: 3rem 0;

    &__consent {
        input {
            float: left;
        }

        p {
            padding-left: 2.7rem;
            margin-top: -0.5rem;
        }
    }

    &__actions {
        display: grid;
        justify-items: center;
    }

    @include media(">=t-m") {
        grid-gap: 3rem;
        grid-template-columns: 16.5rem auto 34rem;
        margin-bottom: 4rem;

        &__header,
        &__info {
            grid-column: 1 / 4;
        }

        &__items {
            grid-column: 1 / 3;
        }

        &__actions {
            grid-column: 2 / 3;
            justify-items: end;
        }

        &__consent {
            grid-column: 3 / 4;
            grid-row: span 2;
        }
    }

    @include media("<t-m") {
        padding: 0 2rem;
    }
}
