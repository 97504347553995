.c-captcha {
	outline: 0;
	border: 1px solid $color_gray_2;
	border-radius: 0.3rem;
	font-weight: bold;
	font-size: 3rem;
	letter-spacing: 0.06rem;
	text-align: center;
	min-height: 10rem;
	position: relative;

	& > * {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		white-space: pre;
	}
}