.c-display-header {
    display: grid;
    grid-gap: 3rem;

    @include media(">=t-m") {
        grid-template-columns: repeat(auto-fit, minmax(0, auto));
        align-items: center;

        h2, h3, h4, h5 {
            text-align: left;
        }
    }

    &__select {
    	display: grid;
    	grid-template-columns: auto 1fr;
    	justify-content: end;
        align-items: center;

    	label {
    		text-transform: uppercase;
    		margin-right: 1.5rem;
            padding-top: 0;
    	}
    }
}