.c-popup {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $color_gray;
	z-index: 2000;
	overflow-y: auto;
	padding: 0 2rem;

	@include media(">=t-m") {
		padding-bottom: 1.5rem;
	}

	&:not(.is-opened) {
		display: none;
	}

	&__header,
	&__wrapper {
		position: relative;
	}

	&__header {
		padding-top: 1.5rem;

		@include media(">=t-m") {
			display: none;
		}
	}

	&__wrapper {
		margin: 4rem auto 0;

		@include media(">=t-m") {
			margin-top: 11rem;
		}

		& > * {
			padding-bottom: 4rem;
		}
	}

	&__wrapper &__close {
		@include media("<t-m") {
			display: none;
		}
	}

	&--welcome &__wrapper {
		max-width: 69rem;
	}

	&--welcome &__wrapper &__close {
		@include media(">=t-m") {
			right: -3rem;
		}
	}

	&--upsell &__wrapper {
		text-align: center;
	}

	&__close {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
	}
}