.s-footer-menu {
	padding: 4rem 0;

	h2, h3, h4, h5 {
		text-align: left;
	}

	ol,
	ul {
		margin-top: 3rem;
	}
}