.c-nav {
	li {
		position: relative;
		white-space: nowrap;
		margin: 2.5rem 0 0;

		&:first-child {
			margin-top: 0;
		}

		a,
		span {
			display: inline-block;
		}

		& > a:first-child:nth-last-child(2) {
			background-position: 100% 2.3rem;
			background-repeat: no-repeat;
		}

		& > a,
		& > span {
			padding: 1.5rem 0;
			width: 100%;
			text-decoration: none;
			font-size: 2.3rem;
			font-weight: bold;
		}

		ul {
			margin: 0;
			grid-column: 1 / 3;
			grid-row: 2 / 3;

			li {
				background: none;
				float: none;
				width: 100%;
				margin-top: 0;

				a {
					font-size: inherit;
					font-weight: initial;
				}
			}
		}
	}

	& > li {
		& > a,
		& > span {
			color: $color_black_2;
		}

		a:first-child:nth-last-child(2),
		div:before {
			background-image: url('../img/svg/nav_arrow_blue.svg');
		}
	}

	&--expand {
		li {
			display: grid;
			grid-template-columns: auto 5rem;
			grid-template-rows: auto auto;

			& > a,
			& > span {
				grid-column: 1 / 3;
				grid-row: 1 / 2;
				cursor: pointer;
			}

			div {
				grid-column: 2 / 3;
				grid-row: 1 / 2;
				cursor: pointer;
				width: 100%;
				line-height: normal;
				position: relative;
				
				&:before {
					position: absolute;
					content: '';
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-position: 50% 50%;
					background-repeat: no-repeat;
				}

				&:after {
					padding-bottom: 100%;
					content: '';
					display: inline-block;
					vertical-align: middle;
				}
			}

			&:not(.is-opened) {
				ul {
					display: none;
				}

				div:before {
					transform: rotate(180deg);
				}
			}
		}
	}

	&--dark {
		li > a,
		li > span {
			padding: 1.5rem;
		}

		& > li {
			& > a,
			& > span {
				color: $color_white;
			}

			a:hover {
				background-color: $color_blue;
			}

			ul a {
				color: $color_yellow;
			}

			a:first-child:nth-last-child(2),
			div:before {
				background-image: url('../img/svg/nav_arrow_yellow.svg');
			}
		}
	}
}